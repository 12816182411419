<template>
  <SubPageWrapper
    class="integrations"
    empty-cols
  >
    <CreateIntegrationModal />

    <template #loader>
      <SlOverlay :show="isLoading" />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb
        v-if="isProjectEditing"
        @click="handleGoToDemand"
      >
        {{ $t('Settings.TabNames.project') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        v-else
        @click="handleGoToProjects"
      >
        {{ $t('Main.Ui.btStartPageProjects') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ projectName }}
      </SlBreadcrumb>
    </template>

    <template #actions>
      <div
        v-tooltip="getTooltip({
          content: $t('Web.Integrations.Tooltips.ImportDisabled'),
          disabled: canCreateProject
        })"
      >
        <SlButton
          v-if="isProjectEditing"
          @click="handleReimport"
        >
          {{ $t('Web.Integrations.Actions.BtnReimport') }}
        </SlButton>
        <SlButton
          v-else
          :disabled="!canCreateProject"
          @click="handleImport"
        >
          {{ $t('Web.Integrations.Actions.BtnImport') }}
        </SlButton>
      </div>
    </template>

    <ContentBlock>
      <div class="integrations__title heading-3-sb grey-100">
        {{ $t('Web.Integrations.Title') }}
      </div>
      <div
        v-if="!isProjectEditing"
        class="integrations__block"
      >
        <div class="integrations__block-content integrations__block-content--grouping-data">
          <SlControl
            v-model="groupByModel"
            :options="groupByOptions"
            :label="$t('Web.Integrations.Form.GroupProjectBy')"
          >
            <template #icon>
              <SlInfoButton v-tooltip.bottom="getTooltip($t('Web.Integrations.Tooltips.GroupProjectBy'))" />
            </template>
          </SlControl>
<!-- hidden because https://gmdhsoftware.atlassian.net/browse/WSL-2520?focusedCommentId=23827 -->
<!-- v-if="groupByModel === groupBy.MONTH" -->
          <SlValidate
            v-if="false"
            ref="startFromProvider"
            v-slot="{ invalid }"
            key="startFromDay"
            vid="startFromDay"
            :rules="{
              required: true,
              between: {
                min: 1,
                max: 31,
                message: $t('Web.Validation.InvalidValue')
              }
            }"
          >
            <SlControlInput
              v-model="startFromModel"
              :min="1"
              :max="31"
              :label="$t('Web.DbImport.LabelStartFromDay')"
              :is-invalid="invalid"
            >
              <template #icon>
                <SlInfoButton v-tooltip.bottom="getTooltip($t('Web.DbImport.LabelDescrStartFrom'))" />
              </template>
            </SlControlInput>
          </SlValidate>
          <SlSelect
            v-if="groupByNoMonth"
            v-model="startFromModel"
            :select-label="$t('Web.DbImport.LabelStartFrom')"
            :options="startFromOptions"
            inline
          />
        </div>
        <div class="integrations__block-content">
          <SlCheckbox
            v-if="!isProjectEditing"
            v-model="combineLocationsModel"
            :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
          />
          <SlInfoItem
            v-else
            :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
            :value="combineLocationsValueText"
          />
        </div>
      </div>
      <div class="integrations__block">
        <div class="integrations__block-heading">
          {{ $t('Web.Integrations.TitleImport') }}
        </div>
        <div class="integrations__block-content">
          <IntegrationCard
            v-if="hasAggregation"
            :key="aggregationItem.id"
            v-tooltip.bottom="getTooltip({
              content: $t('Web.Integrations.Tooltips.AggregationDisabled'),
              disabled: !aggregationDisabled,
              style: 'width: 300px;'
            })"
            :integration="aggregationItem"
            :disabled="aggregationDisabled"
            @open="handleAggregationOpen"
          />
          <IntegrationCard
            v-for="integration in importIntegrations"
            :key="integration.id"
            v-tooltip.bottom="getIntegrationTooltip(integration)"
            :integration="integration"
            :nested="hasAggregation"
            :is-invalid="validated"
            @open="handleSettingsOpen"
          />
          <SlButton
            v-if="!isProjectEditing"
            @click="handleAddIntegration({
              module: integrationModuleTypes.IMPORT,
              restrict: isRestrictedFromAdding[integrationModuleTypes.IMPORT]
            })"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-20 color-white-stroke"
              />
            </template>
            {{ $t('Web.Integrations.Actions.BtnAddConnection') }}
            <template
              v-if="isRestrictedFromAdding[integrationModuleTypes.IMPORT]"
              #append
            >
              <icon
                data="@icons/upgrade-plan.svg"
                class="fill-off size-16"
              />
            </template>
          </SlButton>
        </div>
      </div>
      <div class="integrations__block">
        <div class="integrations__block-heading">
          {{ $t('Web.Integrations.TitleExport') }}
        </div>
        <div class="integrations__block-content">
          <div class="integrations__block-subheading">
            {{ $t('Web.Integrations.TitleExportPO') }}
          </div>
          <IntegrationCard
            v-for="integration in exportOrdersIntegrations"
            :key="integration.id"
            :integration="integration"
            @open="handleSettingsOpen"
          />
          <SlButton
            :disabled="!!exportOrdersIntegrations.length"
            @click="handleAddIntegration({
              module: integrationModuleTypes.EXPORT,
              exportType: integrationExportTypes.PLANNED_ORDERS
            })"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-20 color-white-stroke"
              />
            </template>
            {{ $t('Web.Integrations.Actions.BtnAddConnection') }}
          </SlButton>
        </div>
        <div class="integrations__block-content">
          <div class="integrations__block-subheading">
            {{ $t('Web.Integrations.TitleExportReports') }}
          </div>
          <IntegrationCard
            v-for="integration in exportReportsIntegrations"
            :key="integration.id"
            :integration="integration"
            @open="handleSettingsOpen"
          />
          <SlButton
            :disabled="!!exportReportsIntegrations.length"
            @click="handleAddIntegration({
              module: integrationModuleTypes.EXPORT,
              exportType: integrationExportTypes.REPORTS
            })"
          >
            <template #prepend>
              <icon
                data="@icons/plus.svg"
                class="fill-off size-20 color-white-stroke"
              />
            </template>
            {{ $t('Web.Integrations.Actions.BtnAddConnection') }}
          </SlButton>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import IntegrationCard from '@/components/Integrations/IntegrationCard.vue';
import CreateIntegrationModal from '@/components/Modals/Integrations/CreateIntegrationModal.vue';
import { modal } from '@/mixins/modal';
import { metaInfo } from '@/mixins/metaInfo';
import {
  integrationExportTypes,
  integrationModuleTypes,
  integrationStatuses,
  integrationTypes
} from '@/config/integrations';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { groupBy, groupByOptions } from '@/config/connection';
import { weekDays } from '@/config/utils/dateInfo.config';
import { updateFgsFlags } from '@/config/api/operations.config';
import { updateData, logLevel } from '@/config/project/updateData.config';
import { getTooltip } from '@/helpers/shared/tooltip';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { $amp_logEvent } from '@/plugins/product/amplitude';

export default {
  name: 'Integrations',
  components: {
    CreateIntegrationModal,
    IntegrationCard
  },
  mixins: [modal, metaInfo],
  data() {
    return {
      groupBy,
      getTooltip,
      integrationTypes,
      integrationModuleTypes,
      integrationExportTypes,
      groupByOptions: groupByOptions(this),
      startFromOptions: weekDays(this),
      validated: false,
      isLoading: false
    };
  },
  provide() {
    return {
      handleSettingsOpen: this.handleSettingsOpen
    };
  },
  computed: {
    ...mapState({
      projectSettings: state => state.integrations.project_settings,
      transformState: state => state.integrations.transform.import,
      aggregationStatus: state => state.integrations.aggregation_status
    }),
    ...mapGetters({
      isFreePlan: 'initialization/isFreePlan',
      currentProject: 'manageProjects/currentProjectMeta',
      importIntegrations: 'integrations/importIntegrations',
      exportIntegrations: 'integrations/exportIntegrations',
      existingNames: 'integrations/existingNames',
      isProjectEditing: 'integrations/isProjectEditing',
      canCreateProject: 'integrations/canCreateProject'
    }),
    exportOrdersIntegrations() {
      return this.exportIntegrations.filter(integration => integration.exportType === integrationExportTypes.PLANNED_ORDERS);
    },
    exportReportsIntegrations() {
      return this.exportIntegrations.filter(integration => integration.exportType === integrationExportTypes.REPORTS);
    },
    groupByNoMonth() {
      return this.groupByModel !== groupBy.MONTH;
    },
    groupByModel: {
      get() {
        return this.projectSettings.groupBy;
      },
      async set(value) {
        const oldOption = this.groupByOptions.find(option => option.value === value) || {};

        this.$set(oldOption, 'loading', true);

        // need both params in case of groupBy.MONTH input available
        await this.updateProjectSettings({
          groupBy: value,
          start: value === groupBy.MONTH ? 1 : 0
        });

        this.$set(oldOption, 'loading', false);
      }
    },
    startFromModel: {
      get() {
        return this.projectSettings.start;
      },
      async set(value) {
        const isValid = await this.validateStartFrom(value);

        // need both params in case of groupBy.MONTH input available
        await this.updateProjectSettings({
          groupBy: this.groupByModel,
          start: +value,
          isValid
        });
      }
    },
    combineLocationsModel: {
      get() {
        return this.projectSettings.combineLocs;
      },
      set(value) {
        this.updateProjectSettings({
          combineLocs: value
        });
      }
    },
    combineLocationsValueText() {
      return getLabelFromBoolean(this.combineLocationsModel, this);
    },
    hasAggregation() {
      return this.importIntegrations.length > 1;
    },
    aggregationDisabled() {
      return this.importIntegrations.some(integration => integration.status !== integrationStatuses.POPULATED);
    },
    projectName() {
      return this.currentProject?.name || '';
    },
    isRestrictedFromAdding() {
      return {
        [integrationModuleTypes.IMPORT]: this.importIntegrations.length && this.isFreePlan
      };
    },
    aggregationItem() {
      return {
        id: integrationTypes.AGGREGATION,
        type: integrationTypes.AGGREGATION,
        module: integrationModuleTypes.IMPORT,
        status: this.aggregationStatus,
        name: this.$t('Web.Integrations.Aggregation.BlockName'),
        description: this.$t('Web.Integrations.Aggregation.BlockDescription')
      };
    }
  },
  beforeMount() {
    this.initializeIntegrations();
    preventTabClose();
  },
  methods: {
    ...mapActions({
      subscribe: 'operations/subscribe',
      initializeIntegrations: 'integrations/initializeIntegrations',
      updateProjectSettings: 'integrations/updateProjectSettings',
      setActiveIntegration: 'integrations/setActiveIntegration',
      setActiveSteps: 'integrations/setActiveSteps',
      createIntegration: 'integrations/createIntegration',
      fetchIntegrationById: 'integrations/fetchIntegrationById',
      resetIntegrationState: 'integrations/resetIntegrationState',
      stepsParser: 'integrations/stepsParser',
      importProject: 'integrations/importProject',
      reimportProject: 'integrations/reimportProject',
      openProject: 'manageProjects/openProject'
    }),
    async validateStartFrom(value) {
      if (!this.$refs.startFromProvider) {
        return true;
      }

      const { valid } = await this.$refs.startFromProvider.$refs.provider.validate(value);

      return valid;
    },
    getValidationTooltipDisabled(integration) {
      if (this.validated) {
        return false;
      }

      return integration.status !== integrationStatuses.FAILED_TO_IMPORT;
    },
    getIntegrationTooltip(integration) {
      let validationTooltipText = '';

      if (integration.status === integrationStatuses.FAILED_TO_IMPORT) {
        validationTooltipText = integration?.error?.message ?? this.$t('Web.Integrations.Tooltips.FailedToImport');
      }

      if (integration.status === integrationStatuses.UNINITIALIZED) {
        validationTooltipText = this.$t('Web.Integrations.Tooltips.Uninitialized');
      }

      if (!validationTooltipText) {
        return null;
      }

      return getTooltip({
        content: validationTooltipText,
        disabled: this.getValidationTooltipDisabled(integration),
        shown: this.validated,
        type: 'error'
      });
    },
    handleAddIntegration({ module, exportType, restrict }) {
      if (restrict) {
        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      this.showModal(modalsId.CREATE_INTEGRATION, {
        module,
        exportType,
        existingNames: this.existingNames,
        createCallback: async(payload) => {
          const data = await this.createIntegration(payload);

          if (payload.id) {
            return;
          }

          this.handleSettingsOpen({
            ...data,
            module,
            exportType
          });
        }
      });
    },
    async handleAggregationOpen({ type, module }) {
      await this.setActiveIntegration({ type, module });

      return this.$router.push({
        name: routeNames.AGGREGATION
      });
    },
    async handleSettingsOpen(data) {
      try {
        const { id, module, type } = data;

        // server fetch file on open it may take some time
        if (type === integrationTypes.SPREADSHEET) {
          this.isLoading = true;
        }

        await this.resetIntegrationState();
        await this.setActiveIntegration(data);
        await this.fetchIntegrationById(data);

        const { steps, index } = await this.stepsParser(data);

        if (!steps) {
          return;
        }

        await this.setActiveSteps(steps);

        this.$router.push({
          name: module === integrationModuleTypes.IMPORT
            ? routeNames.IMPORT_INTEGRATION
            : routeNames.EXPORT_INTEGRATION,
          params: {
            ...this.$route.params,
            integrationId: id,
            step: steps[index].type
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    async validate() {
      this.validated = true;

      const isStartFromValid = await this.validateStartFrom(this.startFromModel);
      const valid = this.importIntegrations.every(integration => integration.status === integrationStatuses.POPULATED);

      if (!valid) {
        this.$notify({
          type: 'error',
          title: this.$t('Web.Integrations.Errors.ImportTitle'),
          text: this.$t('Web.Integrations.Errors.SomeIntegrationsNotComplete'),
          duration: -1
        });
      }

      return valid && isStartFromValid;
    },
    handleGoToProjects() {
      this.$router.push({
        name: routeNames.PROJECT_LIST
      });
    },
    handleGoToDemand() {
      this.$router.push({
        name: routeNames.DEMAND
      });
    },
    async handleImport() {
      this.validated = false;

      const isValid = await this.validate();

      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.subscribe({
          subscriber: this.importProject,
          ignoreLastChanges: true,
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId);
            }
          }
        });

        this.openProject({
          pid: this.$route.params.projectId
        });

        $amp_logEvent('Project Created');
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 9000
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async handleReimport() {
      this.validated = false;

      const isValid = await this.validate();

      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;

        await this.subscribe({
          subscriber: () => this.reimportProject({
            type: updateData.FULL,
            logLevel: logLevel.OFF
          })
        });

        this.$router.push({
          name: routeNames.DEMAND
        });
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 9000
          });
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_color.scss";
@import "@/style/utils/_mixins.scss";

.subpage-wrapper.integrations {
  height: calc(100% + 8px);

  ::v-deep {
    .subpage-wrapper__left-col {
      height: calc(100% - 40px);
    }

    .subpage-wrapper__main {
      height: calc(100vh - 32px) !important;

      .subpage__breadcrumbs {
        width: calc(100% - 100px);

        .sl-breadcrumb:first-child {
          min-width: fit-content;
        }
      }
    }
  }
  .integrations {
    &__title {
      margin-bottom: 20px;
    }

    &__block {
      margin-bottom: 24px;
      padding-bottom: 24px;

      border-bottom: 1px solid $grey-30;

      &:last-child {
        margin-bottom: 0;

        border-bottom: none;
      }

      &-heading {
        margin-bottom: 20px;

        @include heading-4-sb;
        color: $grey-100;
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &:not(:first-child) {
          margin-top: 32px;
        }

        &--grouping-data {
          display: flex;
          flex-direction: row;
          gap: 20px;
          margin-bottom: 20px;

          ::v-deep .sl-control__label-title {
            white-space: nowrap;
          }
        }
      }

      &-subheading {
        @include heading-5-sb;
        color: $grey-100;
      }
    }
  }
}
</style>
