import regExp from '@/helpers/utils/regExp';

export const parseCsv = (str) => {
  if (!str) {
    return [];
  }

  const matchedValues = [...str.matchAll(regExp.parseCsv)];

  return matchedValues.map(match => match[1] || match[2]);
};
