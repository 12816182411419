import Vue from 'vue';
import projectApi from '@/api/project';
import i18n from '@/plugins/vue-i18n';
import statusCodes from '@/config/utils/statusCodes.config';
import { releasedConnectors } from '@/config/connection';
import {
  treeOrders,
  noLocationTreeOrders,
  noChannelTreeOrders
} from '@/config/demand/tree/tree.config';
import { namespaceByRoute } from '@/config/report';
import { periodTypes } from '@/config/project';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { getDifferenceInDays } from '@/helpers/date/getDifferenceDays';
import { MIN_IN_HOUR, MIN_IN_DAY, timezoneOffsetMinutes, convertHourToReadableFormat } from '@/helpers/date/date';
import { toArray } from '@/helpers/utils/toArray';
import { getRouteName } from '@/helpers/shared/router';

const SERVER_UPDATE_MINUTES = 240; // 4:00 AM

const types = {
  SET_PROJECT: 'SET_PROJECT',
  SET_PROJECT_STATS: 'SET_PROJECT_STATS',
  SET_PROJECT_TUTORIALS: 'SET_PROJECT_TUTORIALS',
  SET_ITEM_CODES: 'SET_ITEM_CODES',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_CHANNELS: 'SET_CHANNELS',
  SET_IS_PROJECT_UPDATING: 'SET_IS_PROJECT_UPDATING',
  RESET_STATE: 'RESET_STATE',
  SET_HOLIDAYS: 'SET_HOLIDAYS',
  SET_RAM_STATS: 'SET_RAM_STATS',
  SET_FREEZE_SETTINGS: 'SET_FREEZE_SETTINGS'
};

const initialState = () => ({
  project: null,
  tutorials: null,
  item_codes: [],
  locations: [],
  channels: [],
  isUpdating: false,
  holidays: [],
  ram_stats: {},
  freeze_settings: {}
});

const state = initialState();

const getters = {
  connectorType: (state, getters) => {
    if (getters.isMultipleConnections) {
      return state.project?.connectorTypes;
    }

    return state.project?.connectorTypes?.[0];
  },
  projectDates: (state, _, rootState, rootGetters) => state.project?.dates?.points?.map((point, i) => ({
    val: dateByLocaleKey(point, rootGetters['demand/chart/chartUnitLocale']),
    id: i
  })) || [],
  nRealPoints: (state) => state.project?.dates?.nRealPoints,
  hasLocation: (state) => state.project?.tree?.hasLocation,
  hasChannel: (state) => state.project?.tree?.hasChannel,
  hasAbc: (state) => state.project?.tree?.hasAbc,
  hasBom: (state) => state.project?.inventory?.hasBom,
  hasBatchesOnHand: (state) => state.project?.inventory?.hasBatchesOnHand,
  baseCurrency: (state) => state.project?.currency,
  wantInvModel: state => state.project?.inventory?.wantInvModel,
  pKnown: state => state.project?.pKnown?.tInventory + Math.floor(state.project?.pKnown?.part),
  canExport: state => state.project?.canExport,
  canAutoExport: state => state.project?.canAutoExport,
  canAutoUpdate: state => state.project?.canAutoUpdate,
  wasOCImported: state => state?.project?.inventory?.wasOCImported,
  hasInv: state => state?.project?.inventory?.hasInv,
  hasDistrib: state => state?.project?.inventory?.hasDistrib,
  hasCollections: state => state?.project?.inventory?.hasCollections,
  period: state => {
    const predictor = state.project?.predictor;

    if (!predictor) {
      return null;
    }

    const { dateCycle, delta, cycleSize } = predictor;

    if (dateCycle === 'month_year' && delta === 1) {
      return periodTypes.MONTH;
    }

    if ((dateCycle === 'day_week' && cycleSize === 1)
      || (dateCycle === 'uniform' && delta === 7)) {
      return periodTypes.WEEK;
    }

    return periodTypes.UNSUPPORTED;
  },
  isSupportedPeriod: (_, getters) => {
    return getters.period && getters.period !== periodTypes.UNSUPPORTED;
  },
  itemCodes: state => state.item_codes || [],
  lastUpdate: (state) => {
    if (!state?.project) {
      return '';
    }

    const { updateFailed, lastUpdate = '' } = state.project;
    const delta = getDifferenceInDays(lastUpdate);

    if (delta <= 0) {
      return updateFailed ? i18n.tc('Main.LastUpdateFailed.Today') : i18n.tc('Main.LastUpdateDate.Today');
    }

    if (delta === 1) {
      return updateFailed ? i18n.tc('Main.LastUpdateFailed.Yesterday') : i18n.tc('Main.LastUpdateDate.Yesterday');
    }

    if (delta <= 30) {
      if (updateFailed) {
        return i18n.t('Main.LastUpdateFailed.NDaysAgo.val', {
          p1: i18n.tc('Main.LastUpdateFailed.NDaysAgo.p1', delta, { n: delta })
        });
      }

      return i18n.t('Main.LastUpdateDate.NDaysAgo.val', {
        p1: i18n.tc('Main.LastUpdateDate.NDaysAgo.p1', delta, { n: delta })
      });
    }

    const locDate = dateByLocaleKey(lastUpdate, localeDateKeys.YMD);

    if (updateFailed) {
      return i18n.tc('Main.LastUpdateFailed.ProperDate') + ' ' + locDate;
    }

    return i18n.tc('Main.LastUpdateDate.ProperDate') + ' ' + locDate;
  },
  localUpdateTime: (state) => {
    const serverUpdateTime = state.project.recalcTimeUtc;
    const serverTimezoneOffsetMinutes = serverUpdateTime - SERVER_UPDATE_MINUTES;

    const userTimezoneOffsetMinutes = timezoneOffsetMinutes();
    const userUpdateTimeMinutes = (SERVER_UPDATE_MINUTES + (userTimezoneOffsetMinutes - serverTimezoneOffsetMinutes)) % MIN_IN_DAY;
    const userUpdateTimeHours = userUpdateTimeMinutes / MIN_IN_HOUR;

    return convertHourToReadableFormat(userUpdateTimeHours);
  },
  dataAsOf: (state) => {
    return state.project ? dateByLocaleKey(state.project?.actualDate, localeDateKeys.YMD) : '';
  },
  calculationDate: (state) => {
    return state.project ? dateByLocaleKey(state.project?.calculationDate, localeDateKeys.YMD) : '';
  },
  canEditConnection: (state, getters) => {
    return releasedConnectors.includes(getters.connectorType) || state.project?.isConnector2_0;
  },
  isMultipleConnections: (state) => state.project?.isConnector2_0,
  projectLabel: (state) => {
    const projectName = state.project?.fileName ?? '';

    return projectName.replace('.gsl', '');
  },
  activeFilterId: (_, __, ___, rootGetters) => () => {
    const namespace = namespaceByRoute[getRouteName()];

    return rootGetters[`${namespace}/activeFilterId`];
  },
  holidaysSet: (state) => {
    return toArray(state.holidays).map(holiday => {
      return {
        text: holiday,
        value: holiday,
        icon: 'calendar'
      };
    });
  }
};

const mutations = {
  [types.SET_PROJECT](state, value) {
    state.project = value;
  },
  [types.SET_PROJECT_STATS](state, value) {
    Vue.set(state.project, 'stats', value);
  },
  [types.SET_PROJECT_TUTORIALS](state, value) {
    state.tutorials = value;
  },
  [types.SET_ITEM_CODES](state, value) {
    state.item_codes = value;
  },
  [types.SET_LOCATIONS](state, value) {
    state.locations = value;
  },
  [types.SET_CHANNELS](state, value) {
    state.channels = value;
  },
  [types.SET_IS_PROJECT_UPDATING](state, value) {
    state.isUpdating = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.SET_HOLIDAYS](state, value) {
    state.holidays = value;
  },
  [types.SET_RAM_STATS](state, value) {
    state.ram_stats = value;
  },
  [types.SET_FREEZE_SETTINGS](state, value) {
    state.freeze_settings = value;
  }
};

const actions = {
  setIsProjectUpdating({ commit }, value) {
    commit(types.SET_IS_PROJECT_UPDATING, value);
  },
  async fetchProject({ commit, dispatch, getters }) {
    try {
      const response = await projectApi.getProjectData();

      if (!response?.data) {
        return;
      }

      commit(types.SET_PROJECT, response.data);

      dispatch(
        'demand/chart/setChartUnit',
        { unit: response.data.predictor.dateProgression },
        { root: true }
      );

      const hasChannel = getters.hasChannel;
      const hasLocation = getters.hasLocation;

      let currentTreeOrders = [];

      if (hasChannel && hasLocation) {
        currentTreeOrders = treeOrders();
      } else if (hasChannel) {
        currentTreeOrders = noLocationTreeOrders();
      } else if (hasLocation) {
        currentTreeOrders = noChannelTreeOrders();
      }

      await this.dispatch('demand/tree/setTreeOrders', currentTreeOrders);

      const stats = await projectApi.getProjectStatistics();

      if (stats?.data) {
        commit(types.SET_PROJECT_STATS, stats.data);
      }

      return response.data;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProject' });
    }
  },
  async fetchProjectTabs() {
    try {
      const response = await projectApi.getProjectTabs();

      return response?.data?.tabs;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProjectTabs' });
      throw e;
    }
  },
  reImport(_, payload) {
    return projectApi.postReimport(payload);
  },
  async fetchMetaInfo(_, id) {
    try {
      const response = await projectApi.getReimportMetaInfo(id);

      return response.data?.importIssues;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchMetaInfo' });
    }
  },
  async saveProject() {
    try {
      await projectApi.saveProject();

      Vue.notify({
        type: 'success',
        title: i18n.tc('Common.Save'),
        text: i18n.tc('Web.SaveProject.Success')
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveProject' });
    }
  },
  async getSystemVersion() {
    try {
      const response = await projectApi.getSystemVersion();

      const serverVersion = response?.data?.serverVersion;

      if (process.env.VUE_APP_MODE === 'production' && window.location.hostname !== 'localhost') {
        projectApi.postServerVersion({
          version: serverVersion,
          domain: window.location.origin
        });
      }

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getSystemVersion' });

      throw e;
    }
  },
  async fetchTutorials({ commit }) {
    try {
      const response = await projectApi.getTutorials();

      const metadata = response?.data?.Metadata;

      if (!metadata) {
        return;
      }

      const tutorials = toArray(metadata.Tutorials.Tutorial).map((tutorial) => ({
        ...tutorial,
        _tags: tutorial._tags?.split(',') || []
      }));

      commit(types.SET_PROJECT_TUTORIALS, tutorials);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTutorials' });
    }
  },
  async fetchItemCodes({ commit, getters }, { query } = {}) {
    try {
      if (!query) {
        return;
      }

      const response = await projectApi.getItemCodes({
        id: getters.activeFilterId(),
        query,
        top: 20,
        skip: 0
      });
      const items = response.data?.items;

      if (items) {
        commit(types.SET_ITEM_CODES, items);
      }

      return items || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchItemCodes' });
    }
  },
  async fetchLocations({ commit, getters }, { query = '', item } = {}) {
    try {
      const response = await projectApi.getLocations({
        id: getters.activeFilterId(),
        query,
        item,
        top: 20,
        skip: 0
      });
      const locations = response.data?.locations;

      if (locations) {
        commit(types.SET_LOCATIONS, locations);
      }

      return locations || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchLocations' });
    }
  },
  async fetchChannels({ commit, getters }, { query = '', item, location } = {}) {
    try {
      const response = await projectApi.getChannels({
        id: getters.activeFilterId(),
        query,
        item,
        location,
        top: 20,
        skip: 0
      });
      const channels = response.data?.channels;

      if (channels) {
        commit(types.SET_CHANNELS, channels);
      }

      return channels || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChannels' });
    }
  },
  setItemCodes({ commit }, value) {
    commit(types.SET_ITEM_CODES, value);
  },
  setLocations({ commit }, value) {
    commit(types.SET_LOCATIONS, value);
  },
  setChannels({ commit }, value) {
    commit(types.SET_CHANNELS, value);
  },
  resetItemCodes({ commit }) {
    commit(types.SET_ITEM_CODES, []);
  },
  downloadTruncatedProject({ rootGetters }) {
    return projectApi.downloadTruncatedProject({
      nodeId: rootGetters['demand/tree/activeNodeId']
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  async getHolidays({ commit }) {
    try {
      const response = await projectApi.getHolidays();
      const holidays = response?.data?.holidays;

      if (!holidays) {
        return;
      }

      commit(types.SET_HOLIDAYS, holidays);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getHolidays' });
    }
  },
  async fetchRamStats({ commit, rootGetters }) {
    const isFreePlan = rootGetters['initialization/isFreePlan'];

    if (isFreePlan) {
      return;
    }

    try {
      const response = await projectApi.getRamStats();
      const stats = response?.data?.stats;

      if (!stats) {
        return;
      }

      commit(types.SET_RAM_STATS, {
        totalRAM: +stats._totalRAM,
        peakProcessRAM: +stats._peakProcessRAM,
        currentProcessRAM: +stats._currentProcessRAM
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchRamStats' });
    }
  },
  async fetchFreezeSettings({ commit }) {
    try {
      const response = await projectApi.getFreezeSettings();
      const freezeSettings = response?.data;

      if (!freezeSettings) {
        return;
      }

      commit(types.SET_FREEZE_SETTINGS, freezeSettings);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFreezeSettings' });
    }
  },
  unfreezeProject({ dispatch }) {
    return dispatch('submitFreezeSettings', { frozen: false });
  },
  async submitFreezeSettings(_, payload) {
    try {
      await projectApi.putFreezeSettings(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'submitFreezeSettings' });
    }
  },
  exportBomIssues(_, id) {
    return projectApi.exportBomIssues({ id });
  },
  async updateSlVersion(_, version) {
    try {
      const response = await projectApi.putUpdateSlVersion({
        type: version
      });

      if (response?.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.UpdateSl.SuccessfulSlUpdate')
        });
      }
    } catch (e) {
      Vue.notify({
        type: 'error',
        text: e.message
      });

      this.dispatch('user/logout', { e, from: 'updateSlVersion' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
