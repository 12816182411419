<template>
  <div
    class="sl-combobox-wrapper"
    :class="{
      'sl-combobox--custom': isComboboxCustom
    }"
  >
    <SlSelect
      ref="select"
      :value="vModel"
      :options="options"
      :track-by="trackBy"
      :clear-on-select="clearOnSelect"
      :multiple="multiple"
      searchable
      preserve-search
      v-bind="$attrs"
      v-on="bindListeners"
      @search-change="handleSearchChange"
      @input="handleInput"
    >
      <template
        v-for="(_, slot) in $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </SlSelect>
  </div>
</template>

<script>
import { keyCodes } from '@/config/utils/statusCodes.config';
import { readClipboardText } from '@/helpers/utils/clipboard';
import { toArray } from '@/helpers/utils/toArray';
import { parseCsv } from '@/helpers/utils/parseCsv';

export default {
  name: 'SlCombobox',
  props: {
    value: {
      type: [String, Number, Object, Array, null],
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    hideInputOnSelection: {
      type: Boolean,
      default: true
    },
    multiple: Boolean,
    clearOnSelect: Boolean
  },
  data() {
    return {
      customValue: ''
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    bindListeners() {
      const { search, input, ...listeners } = this.$listeners;

      return listeners;
    },
    isOptionObject() {
      return this.options.length && typeof this.options[0] === 'object';
    },
    isComboboxCustom() {
      if (this.isOptionObject) {
        return this.customValue && !this.options.some(option => option[this.trackBy] === this.customValue);
      }

      return this.customValue && !this.options.includes(this.customValue);
    }
  },
  mounted() {
    const searchInput = this.getSearchInputNode();

    searchInput.addEventListener('keydown', this.keydownHandler);
    searchInput.addEventListener('paste', this.pasteHandler);

    if (this.isOptionObject || this.multiple) {
      return;
    }

    this.setSearch(this.vModel);
  },
  beforeDestroy() {
    const searchInput = this.getSearchInputNode();

    searchInput.removeEventListener('keydown', this.keydownHandler);
    searchInput.removeEventListener('paste', this.pasteHandler);
  },
  methods: {
    getSearchInputNode() {
      return this.$refs.select.$el.querySelector('.multiselect__input');
    },
    setSearch(value) {
      const multiselect = this.$refs.select.$refs.multiselect;

      multiselect.search = value;

    },
    handleSearchChange(value) {
      // todo handle object options
      if (this.isOptionObject) {
        return;
      }

      if (value !== this.vModel && !this.multiple) {
        this.vModel = value;
      }

      this.customValue = value;

      this.$emit('search', value);
    },
    handleInput(value) {
      this.vModel = value;

      if (this.hideInputOnSelection) {
        this.customValue = this.isOptionObject ? value[this.trackBy] : value;
      }
    },
    keydownHandler(event) {
      if (event.keyCode !== keyCodes.enter) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (this.isComboboxCustom) {
        this.setValue(this.customValue);
      }
    },
    async pasteHandler(event) {
      event.preventDefault();
      event.stopPropagation();

      const clipboardText = await readClipboardText();

      if (this.multiple) {
        return this.setValue(parseCsv(clipboardText));
      }
      this.setSearch(this.customValue + clipboardText);
    },
    setValue(value) {
      this.vModel = this.multiple
        ? [...new Set([...this.vModel, ...toArray(value)])]
        : value;

      this.setSearch('');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-combobox';
</style>