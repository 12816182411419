import BaseImportAdapter from './BaseImport.adapter';
import DatabaseImportAdapter from './DatabaseImport.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration) {
  const adapterByType = {
    [integrationTypes.AGGREGATION]: BaseImportAdapter,
    [integrationTypes.AMAZON]: BaseImportAdapter,
    [integrationTypes.QB_DESKTOP]: BaseImportAdapter,
    [integrationTypes.CUSTOM_API]: BaseImportAdapter,
    [integrationTypes.BC365]: BaseImportAdapter,
    [integrationTypes.DATABASE]: DatabaseImportAdapter,
    [integrationTypes.SAP]: DatabaseImportAdapter,
    [integrationTypes.DYNAMICS_NAV]: DatabaseImportAdapter,
    [integrationTypes.NETSUITE]: DatabaseImportAdapter,
    [integrationTypes.CIN7]: DatabaseImportAdapter
  };

  const adapter = adapterByType[integration.type];

  return adapter ? adapter(settings, integration) : settings;
}
