import spreadsheet from '@/image/svg/connection/spreadsheet.svg';
import database from '@/image/svg/connection/database.svg';
//import multiple_connector from '@/image/svg/connection/multiple_connector.svg';
import cin7 from '@/image/svg/connection/cin7.svg';
import cin7_core from '@/image/svg/connection/cin7_core.svg';
import dynamics_gp from '@/image/svg/connection/dynamics_gp.svg';
import dynamics_nav from '@/image/svg/connection/dynamics_nav.svg';
import fishbowl from '@/image/svg/connection/fishbowl.svg';
import qb from '@/image/svg/connection/qb.svg';
import shopify from '@/image/svg/connection/shopify.svg';
import spire from '@/image/svg/connection/spire.svg';
import unleashed from '@/image/svg/connection/unleashed.svg';
import extensiv from '@/image/svg/connection/extensiv.svg';
import odoo from '@/image/svg/connection/odoo.svg';
import sap from '@/image/svg/connection/sap.svg';
import business_central from '@/image/svg/connection/business_central.svg';
import netsuite from '@/image/svg/connection/netsuite.svg';
import exact from '@/image/svg/connection/exact.svg';
import sellercloud from '@/image/svg/connection/sellercloud.svg';
import pronto_xi from '@/image/svg/connection/pronto_xi.svg';
import custom_api from '@/image/svg/connection/custom_api.svg';
import finale from '@/image/svg/connection/finale.svg';

import DatabaseOptionalTab from '@/components/Connections/DbRelated/Database/Optional';
import DatabasePlannedOrdersTab from '@/components/Connections/DbRelated/Database/PlannedOrders';
import NetsuiteOptionalTab from '@/components/Connections/DbRelated/Netsuite/Optional';
import CinRequiredTab from '@/components/Connections/Cin7/RequiredTab';
import CinOptionalTab from '@/components/Connections/Cin7/OptionalTab';
import CustomRequiredTab from '@/components/Connections/CustomAPI/RequiredTab';
import CustomOptionalTab from '@/components/Connections/CustomAPI/OptionalTab';
import ProntoRequiredTab from '@/components/Connections/Pronto/RequiredTab';
import ProntoOptionalTab from '@/components/Connections/Pronto/OptionalTab';
import DearRequiredTab from '@/components/Connections/Dear/RequiredTab';
import DearOptionalTab from '@/components/Connections/Dear/OptionalTab';
import UnleashedRequiredTab from '@/components/Connections/Unleashed/RequiredTab';
import UnleashedOptionalTab from '@/components/Connections/Unleashed/OptionalTab';
import ShopifyRequiredTab from '@/components/Connections/Shopify/RequiredTab';
import ShopifyOptionalTab from '@/components/Connections/Shopify/OptionalTab';
import ExactRequiredTab from '@/components/Connections/Exact/RequiredTab';
import ExactOptionalTab from '@/components/Connections/Exact/OptionalTab';
import SellercloudRequiredTab from '@/components/Connections/Sellercloud/RequiredTab';
import SellercloudOptionalTab from '@/components/Connections/Sellercloud/OptionalTab';
import BC365RequiredTab from '@/components/Connections/BC365/RequiredTab';
import BC365OptionalTab from '@/components/Connections/BC365/OptionalTab';
import FinaleRequiredTab from '@/components/Connections/Finale/RequiredTab';
import FinaleOptionalTab from '@/components/Connections/Finale/OptionalTab';
import SpireRequiredTab from '@/components/Connections/Spire/RequiredTab';
import SpireOptionalTab from '@/components/Connections/Spire/OptionalTab';
import QBDesktopRequiredTab from '@/components/Connections/QB/QBDesktop/RequiredTab';
import QBDesktopOptionalTab from '@/components/Connections/QB/QBDesktop/OptionalTab';
import QBOnlineRequiredTab from '@/components/Connections/QB/QBOnline/RequiredTab';
import QBOnlineOptionalTab from '@/components/Connections/QB/QBOnline/OptionalTab';
import SkubanaRequiredTab from '@/components/Connections/Skubana/RequiredTab';
import SkubanaOptionalTab from '@/components/Connections/Skubana/OptionalTab';
import DynamicsNavExportTab from '@/components/Connections/DbRelated/DynamicsNav/Export';
import DynamicsNavOptionalTab from '@/components/Connections/DbRelated/DynamicsNav/Optional';
import SapExportTab from '@/components/Connections/DbRelated/Sap/Export';
import SapOptionalTab from '@/components/Connections/DbRelated/Sap/Optional';
import FishbowlExportTab from '@/components/Connections/DbRelated/Fishbowl/Export';
import FishbowlOptionalTab from '@/components/Connections/DbRelated/Fishbowl/Optional';
import DynamicsGpExportTab from '@/components/Connections/DbRelated/DynamicsGp/Export';
import DynamicsGpOptionalTab from '@/components/Connections/DbRelated/DynamicsGp/Optional';
import OdooOptionalTab from '@/components/Connections/DbRelated/Odoo/Optional';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames } from '@/config/router/router.config';
import { bc365ConnectionTypes, sourceConnectData as sourceConnectDataBC365 } from '@/config/connection/BC365.config';
import { sourceConnectData as sourceConnectDataSkubana } from '@/config/connection/skubana.config';
import { tabKeys } from '@/config/connection/dbRelated.config';

export const connectionTypes = {
  // used in internal redirects only
  SPREADSHEET: 'spreadsheet',
  // diff data types === diff connector types
  SPREADSHEET_TRANSACTIONAL: 'orderlist',
  SPREADSHEET_AGGREGATED: 'sheet2d',
  DATABASE: 'qdb',
  MULTIPLE: 'multiple',
  CIN7: 'cin7',
  DEAR: 'dear',
  DYNAMICS_GP: 'gp',
  DYNAMICS_NAV: 'navdb',
  FISHBOWL: 'fishbowl',
  QB_DESKTOP: 'qb',
  SHOPIFY: 'shopify',
  SPIRE: 'spire',
  UNLEASHED: 'unleashed',
  SKUBANA: 'skubana',
  ODOO: 'odoo',
  SAP: 'sapb1',
  BUSINESS_CENTRAL: 'bc365',
  NETSUITE: 'netsuite',
  EXACT: 'exact',
  QB_ONLINE: 'qbo',
  SELLERCLOUD: 'sellercloud',
  PRONTO_XI: 'prontoXi',
  CUSTOM_API: 'customapi',
  FINALE: 'finale'
};

export const dbRelatedConnections = [
  connectionTypes.DATABASE,
  connectionTypes.NETSUITE,
  connectionTypes.DYNAMICS_NAV,
  connectionTypes.SAP,
  connectionTypes.FISHBOWL,
  connectionTypes.DYNAMICS_GP,
  connectionTypes.ODOO
];

export const editRoutes = [
  connectorRouteNames.EDIT_CONNECTION,
  connectorRouteNames.EDIT_CONNECTION_CONNECTOR
];

export const spreadsheetTypes = [
  connectionTypes.SPREADSHEET_TRANSACTIONAL,
  connectionTypes.SPREADSHEET_AGGREGATED
];

export const releasedConnectors = [
  connectionTypes.SPREADSHEET_TRANSACTIONAL,
  connectionTypes.SPREADSHEET_AGGREGATED,
  connectionTypes.DATABASE,
  connectionTypes.DEAR,
  connectionTypes.SHOPIFY,
  connectionTypes.UNLEASHED,
  connectionTypes.PRONTO_XI,
  connectionTypes.CUSTOM_API,
  connectionTypes.CIN7,
  connectionTypes.EXACT,
  connectionTypes.SELLERCLOUD,
  connectionTypes.BUSINESS_CENTRAL,
  connectionTypes.FINALE,
  connectionTypes.NETSUITE,
  connectionTypes.SKUBANA,
  connectionTypes.DYNAMICS_NAV,
  connectionTypes.SPIRE,
  connectionTypes.QB_DESKTOP,
  connectionTypes.QB_ONLINE,
  connectionTypes.SAP,
  connectionTypes.FISHBOWL,
  connectionTypes.DYNAMICS_GP,
  connectionTypes.ODOO
];

export const groupBy = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
};

export const groupByOptions = (ctx) => ([
  {
    label: ctx.$t('Import.GroupType.Month'),
    value: 'month',
    loading: false
  },
  {
    label: ctx.$t('Import.GroupType.Week'),
    value: 'week',
    loading: false
  }
  // {
  //   label: ctx.$t('Import.GroupType.Day'),
  //   value: 'day'
  // }
]);

export const DEFAULT_START_DATE = '2000-01-01';
export const DEFAULT_GROUP_BY = groupBy.MONTH;

export const shopifyAuthTypes = {
  OAUTH: 'oauth',
  CUSTOM: 'customApp'
};

export const connectorStructure = (type) => {
  const { required, optional } = defaultParamsByType[type] || {};

  return {
    required: {
      ...required
    },
    optional: {
      ...optional
    },
    commonData: {
      startDate: DEFAULT_START_DATE,
      groupBy: DEFAULT_GROUP_BY,
      startFrom: 1,
      type
    }
  };
};

export const sourceConnectDataFields = {
  MAIN: 'sourceConnectData',
  CACHE: 'sourceConnectDataCache'
};

const sourceDataByType = {
  [connectionTypes.BUSINESS_CENTRAL]: sourceConnectDataBC365,
  [connectionTypes.SKUBANA]: sourceConnectDataSkubana
};

export const sourceConnectStructure = (type) => {
  return sourceDataByType[type] && sourceDataByType[type]();
};

const defaultParamsByType = {
  [connectionTypes.SHOPIFY]: {
    required: {
      authType: shopifyAuthTypes.OAUTH
    }
  },
  [connectionTypes.FINALE]: {
    optional: {
      authType: shopifyAuthTypes.OAUTH
    }
  },
  [connectionTypes.CUSTOM_API]: {
    required: {
      pageLimit: 10000
    }
  },
  [connectionTypes.QB_DESKTOP]: {
    optional: {
      readPoClass: true,
      fillMissingVendors: true,
      calculateLeadTime: true,
      customFieldAsCategory: ''
    }
  }
};

export const connectionsConfig = (ctx) => ({
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: {
    title: ctx.$t('CreateNewProject.lbSpreadsheetConnectionTitle'),
    icon: spreadsheet,
    key: connectionTypes.SPREADSHEET_TRANSACTIONAL
  },
  [connectionTypes.DATABASE]: {
    title: ctx.$t('DbInsideImport.Ui.lbDatabase'),
    icon: database,
    key: connectionTypes.DATABASE
  },
  [connectionTypes.CUSTOM_API]: {
    title: 'Custom API',
    icon: custom_api,
    key: connectionTypes.CUSTOM_API
  },
  /*[connectionTypes.MULTIPLE]: {
    title: ctx.$t('Main.Ui.acMultipleImport'),
    icon: multiple_connector,
    key: connectionTypes.MULTIPLE
  },*/
  [connectionTypes.CIN7]: {
    title: 'Cin7',
    icon: cin7,
    key: connectionTypes.CIN7
  },
  [connectionTypes.DEAR]: {
    title: 'Cin7 Core',
    icon: cin7_core,
    key: connectionTypes.DEAR
  },
  [connectionTypes.BUSINESS_CENTRAL]: {
    title: 'Dynamics 365 BC',
    icon: business_central,
    key: connectionTypes.BUSINESS_CENTRAL
  },
  [connectionTypes.DYNAMICS_GP]: {
    title: 'Dynamics GP',
    icon: dynamics_gp,
    key: connectionTypes.DYNAMICS_GP
  },
  [connectionTypes.DYNAMICS_NAV]: {
    title: 'Dynamics NAV',
    icon: dynamics_nav,
    key: connectionTypes.DYNAMICS_NAV
  },
  [connectionTypes.EXACT]: {
    title: 'Exact Online',
    icon: exact,
    key: connectionTypes.EXACT
  },
  [connectionTypes.SKUBANA]: {
    title: 'Extensiv',
    icon: extensiv,
    key: connectionTypes.SKUBANA
  },
  [connectionTypes.FINALE]: {
    title: 'Finale Inventory',
    icon: finale,
    key: connectionTypes.FINALE
  },
  [connectionTypes.FISHBOWL]: {
    title: 'Fishbowl',
    icon: fishbowl,
    key: connectionTypes.FISHBOWL
  },
  [connectionTypes.NETSUITE]: {
    title: 'NetSuite',
    icon: netsuite,
    key: connectionTypes.NETSUITE
  },
  [connectionTypes.ODOO]: {
    title: 'Odoo',
    icon: odoo,
    key: connectionTypes.ODOO
  },
  [connectionTypes.PRONTO_XI]: {
    title: 'Pronto Xi',
    icon: pronto_xi,
    key: connectionTypes.PRONTO_XI
  },
  [connectionTypes.QB_DESKTOP]: {
    title: 'QuickBooks Desktop',
    icon: qb,
    key: connectionTypes.QB_DESKTOP
  },
  [connectionTypes.QB_ONLINE]: {
    title: 'QuickBooks Online',
    icon: qb,
    key: connectionTypes.QB_ONLINE
  },
  [connectionTypes.SAP]: {
    title: 'SAP Business One',
    icon: sap,
    key: connectionTypes.SAP
  },
  [connectionTypes.SELLERCLOUD]: {
    title: 'Sellercloud',
    icon: sellercloud,
    key: connectionTypes.SELLERCLOUD
  },
  [connectionTypes.SHOPIFY]: {
    title: 'Shopify',
    icon: shopify,
    key: connectionTypes.SHOPIFY
  },
  [connectionTypes.SPIRE]: {
    title: 'Spire',
    icon: spire,
    key: connectionTypes.SPIRE
  },
  [connectionTypes.UNLEASHED]: {
    title: 'Unleashed',
    icon: unleashed,
    key: connectionTypes.UNLEASHED
  }
});

export const connectorByType = (ctx) => ({
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: {
    modal: modalsId.SPREADSHEET_CONNECTOR
  },
  [connectionTypes.SPREADSHEET_AGGREGATED]: {
    modal: modalsId.SPREADSHEET_CONNECTOR
  },
  [connectionTypes.DATABASE]: {
    title: ctx.$t('DbImportDialog.Ui.:Caption'),
    components: {
      [tabKeys.OPTIONAL]: DatabaseOptionalTab,
      [tabKeys.PLANNED_ORDERS]: DatabasePlannedOrdersTab
    },
    modal: modalsId.DATABASE_CONNECTOR
  },
  [connectionTypes.MULTIPLE]: {
    title: ctx.$t(''),
    components: {
      required: '',
      optional: ''
    }
  },
  [connectionTypes.CIN7]: {
    title: ctx.$t(''),
    components: {
      required: CinRequiredTab,
      optional: CinOptionalTab
    },
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.DEAR]: {
    title: ctx.$t('Main.Ui.acImportFromDear'),
    components: {
      required: DearRequiredTab,
      optional: DearOptionalTab
    },
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.DYNAMICS_GP]: {
    title: ctx.$t('Main.Ui.acImportFromGp'),
    components: {
      [tabKeys.EXPORT]: DynamicsGpExportTab,
      [tabKeys.OPTIONAL]: DynamicsGpOptionalTab
    },
    modal: modalsId.DYNAMICS_GP_CONNECTOR
  },
  [connectionTypes.DYNAMICS_NAV]: {
    title: ctx.$t('Main.Ui.acImportFromNavDb'),
    components: {
      [tabKeys.EXPORT]: DynamicsNavExportTab,
      [tabKeys.OPTIONAL]: DynamicsNavOptionalTab
    },
    modal: modalsId.DYNAMICS_NAV_CONNECTOR
  },
  [connectionTypes.FISHBOWL]: {
    title: ctx.$t('Main.Ui.acImportFromFish'),
    components: {
      [tabKeys.EXPORT]: FishbowlExportTab,
      [tabKeys.OPTIONAL]: FishbowlOptionalTab
    },
    modal: modalsId.FISHBOWL_CONNECTOR
  },
  [connectionTypes.QB_DESKTOP]: {
    title: ctx.$t('Main.Ui.acQbImport'),
    components: {
      required: QBDesktopRequiredTab,
      optional: QBDesktopOptionalTab
    },
    modal: modalsId.QB_DESKTOP_CONNECTOR,
    reconnect: (ctx) => ({
      title: ctx.$t('Main.Ui.acQbImport')
    }),
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.SHOPIFY]: {
    title: ctx.$t(''),
    components: {
      required: ShopifyRequiredTab,
      optional: ShopifyOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.SPIRE]: {
    title: ctx.$t('Main.Ui.acImportFromSpire'),
    components: {
      required: SpireRequiredTab,
      optional: SpireOptionalTab
    },
    modal: modalsId.SPIRE_CONNECTOR,
    reconnect: (ctx) => ({
      title: ctx.$t('Main.Ui.acImportFromSpire')
    }),
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.UNLEASHED]: {
    title: ctx.$t('Main.Ui.acUnleashedImport'),
    components: {
      required: UnleashedRequiredTab,
      optional: UnleashedOptionalTab
    },
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.SKUBANA]: {
    title: '',
    components: {
      required: SkubanaRequiredTab,
      optional: SkubanaOptionalTab
    },
    modal: modalsId.SKUBANA_CONNECTOR,
    reconnect: (ctx) => ({
      title: ctx.$t('Web.Skubana.TitleConnection')
    }),
    validate: [tabKeys.REQUIRED, tabKeys.OPTIONAL]
  },
  [connectionTypes.ODOO]: {
    title: ctx.$t('Main.Ui.acImportFromOdoo'),
    components: {
      [tabKeys.EXPORT]: null,
      [tabKeys.OPTIONAL]: OdooOptionalTab
    },
    modal: modalsId.ODOO_CONNECTOR
  },
  [connectionTypes.SAP]: {
    title: ctx.$t('Main.Ui.acImportFromSapB1'),
    components: {
      [tabKeys.EXPORT]: SapExportTab,
      [tabKeys.OPTIONAL]: SapOptionalTab
    },
    modal: modalsId.SAP_CONNECTOR
  },
  [connectionTypes.BUSINESS_CENTRAL]: {
    components: {
      required: BC365RequiredTab,
      optional: BC365OptionalTab
    },
    modal: modalsId.BC365_CONNECTOR,
    reconnect: (ctx, data) => ({
      button: data?.type === bc365ConnectionTypes.CLOUD
        ? ctx.$t('Web.BaseConnectors.BtnReconnectToMicrosoft')
        : ctx.$t('Web.BaseConnectors.BtnReconnect'),
      title: ctx.$t('Main.Ui.acImportFromBc365')
    })
  },
  [connectionTypes.NETSUITE]: {
    title: '',
    components: {
      [tabKeys.OPTIONAL]: NetsuiteOptionalTab,
      [tabKeys.CHANNEL_INFO]: null,
      [tabKeys.SUBSTITUTIONS]: null,
      [tabKeys.PROMOTIONS]: null,
      [tabKeys.BATCH_EXPIRATIONS]: null,
      [tabKeys.INVENTORY_PARAMETERS]: null,
      [tabKeys.MIN_MAX]: null,
      [tabKeys.INVENTORY_REPORT]: null,
      [tabKeys.INVENTORY_FORECAST]: null,
      [tabKeys.REPORTS]: null
    },
    modal: modalsId.NETSUITE_CONNECTOR
  },
  [connectionTypes.EXACT]: {
    title: ctx.$t(''),
    components: {
      required: ExactRequiredTab,
      optional: ExactOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.QB_ONLINE]: {
    title: '',
    components: {
      required: QBOnlineRequiredTab,
      optional: QBOnlineOptionalTab
    },
    modal: modalsId.QB_ONLINE_CONNECTOR,
    reconnect: (ctx) => ({
      title: ctx.$t('Main.Ui.acImportFromQbo')
    }),
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.SELLERCLOUD]: {
    title: ctx.$t(''),
    components: {
      required: SellercloudRequiredTab,
      optional: SellercloudOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.PRONTO_XI]: {
    title: ctx.$t('Main.Ui.acImportFromProntoXi'),
    components: {
      required: ProntoRequiredTab,
      optional: ProntoOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.CUSTOM_API]: {
    title: 'Custom API',
    components: {
      required: CustomRequiredTab,
      optional:CustomOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  },
  [connectionTypes.FINALE]: {
    title: 'Finale Inventory',
    components: {
      required: FinaleRequiredTab,
      optional: FinaleOptionalTab
    },
    validate: [tabKeys.REQUIRED]
  }
});

export const categorySlots = {
  ITEM_CAT: 'HOLE_ITEM_CAT',
  LOCATION_CAT: 'HOLE_LOCATION_CAT',
  CHANNEL_CAT: 'HOLE_CHANNEL_CAT',
  INFO_FIELD: 'HOLE_AUXFIELD'
};

export const multipleSlots = [
  categorySlots.ITEM_CAT,
  categorySlots.LOCATION_CAT,
  categorySlots.CHANNEL_CAT,
  categorySlots.INFO_FIELD
];
