import CustomApiAdapter from './CustomApi.adapter';
import DatabaseRelatedAdapter from './DatabaseRelated.adapter';
import NetsuiteAdapter from './Netsuite.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration) {
  const adapterByType = {
    [integrationTypes.CUSTOM_API]: CustomApiAdapter,
    [integrationTypes.DATABASE]: DatabaseRelatedAdapter,
    [integrationTypes.SAP]: DatabaseRelatedAdapter,
    [integrationTypes.DYNAMICS_NAV]: DatabaseRelatedAdapter,
    [integrationTypes.NETSUITE]: NetsuiteAdapter
  };

  const adapter = adapterByType[integration.type];

  return {
    type: integration.type,
    ...(adapter ? adapter(settings, integration) : settings)
  };
}
