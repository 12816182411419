import Vue from 'vue';
import settings from '@/store/modules/integrations/settings';
import transform from '@/store/modules/integrations/transform';
import spreadsheet from '@/store/modules/integrations/integrationRelated/spreadsheet';
import amazon from '@/store/modules/integrations/integrationRelated/amazon';
import bc365 from '@/store/modules/integrations/integrationRelated/bc365';
import netsuite from '@/store/modules/integrations/integrationRelated/netsuite';
import integrationsApi from '@/api/integrations';
import connectorApi from '@/api/integrations/connector';
import ProjectSettingsResponseAdapter from '@/adapters/response/integrations/ProjectSettings.adapter';
import IntegrationsResponseAdapter from '@/adapters/response/integrations/Integrations.adapter';
import AvailableIntegrationsResponseAdapter from '@/adapters/response/integrations/AvailableIntegrations.adapter';
import ConfigurationResponseAdapter from '@/adapters/response/integrations/configuration';
import ConnectionResponseAdapter from '@/adapters/response/integrations/connection';
import ConfigurationRequestAdapter from '@/adapters/request/integrations/configuration';
import ConnectionRequestAdapter from '@/adapters/request/integrations/connection';
import {
  connectionlessIntegrations,
  connectionlessExportIntegrations,
  databaseRelatedIntegrations,
  integrationExportTypes,
  integrationModuleTypes,
  integrationsConfig,
  integrationStatuses,
  integrationSteps,
  integrationTypes,
  stepStatuses
} from '@/config/integrations';
import { DEFAULT_GROUP_BY } from '@/config/connection';
import { projectStatuses } from '@/config/project';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { getParsedSteps } from '@/helpers/integration/stepsParser';

const types = {
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  SET_AVAILABLE_INTEGRATIONS: 'SET_AVAILABLE_INTEGRATIONS',
  SET_PROJECT_SETTINGS: 'SET_PROJECT_SETTINGS',
  SET_ACTIVE_INTEGRATION: 'SET_ACTIVE_INTEGRATION',
  SET_ACTIVE_STEPS: 'SET_ACTIVE_STEPS',
  UPDATE_STEP: 'UPDATE_STEP',
  SET_INTEGRATION_INITIALIZED: 'SET_INTEGRATION_INITIALIZED',
  SET_IS_EDITED: 'SET_IS_EDITED',
  SET_AGGREGATION_STATUS: 'SET_AGGREGATION_STATUS',
  SET_CREATION_STATUS: 'SET_CREATION_STATUS',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  integrations: {
    [integrationModuleTypes.IMPORT]: [],
    [integrationModuleTypes.EXPORT]: []
  },
  available_integrations: {
    [integrationModuleTypes.IMPORT]: [],
    [integrationModuleTypes.EXPORT]: []
  },
  active_integration: {},
  active_steps: [],
  project_settings: {
    groupBy: DEFAULT_GROUP_BY,
    start: 0,
    combineLocs: false
  },
  integration_initialized: false,
  aggregation_status: null,
  creation_status: null,
  is_edited: false
});

const state = initialState();

const getters = {
  importIntegrations: state => state.integrations[integrationModuleTypes.IMPORT] || [],
  exportIntegrations: state => state.integrations[integrationModuleTypes.EXPORT] || [],
  allIntegrations: (_, getters) => {
    return [
      ...getters.importIntegrations,
      ...getters.exportIntegrations
    ];
  },
  existingNames: (_, getters) => {
    return getters.allIntegrations?.map(integration => integration.name) ?? [];
  },
  activeStepIndex: (state) => {
    const index = state.active_steps?.findIndex(step => step.active);

    return index !== -1 ? index : 0;
  },
  activeStep: (state, getters) => state.active_steps[getters.activeStepIndex] || {},
  isActiveStepCompleted: (_, getters) => getters.activeStep?.status === stepStatuses.COMPLETED,
  isAllStepsCompleted: (state) => {
    return state.active_steps.every(step => step.status === stepStatuses.COMPLETED);
  },
  isIntegrationEditing: (state) => {
    return state.active_integration.status === integrationStatuses.POPULATED
      && getters.isAllStepsCompleted;
  },
  isProjectEditing: (_, __, ___, rootGetters) => {
    const project = rootGetters['manageProjects/currentProjectMeta'];

    return project?.status === projectStatuses.RUNNING;
  },
  canCreateProject: (state) => state.creation_status,
  isDatabaseRelated: (state) => databaseRelatedIntegrations.includes(state.active_integration.type),
  needIntegrationSettings: () => ({ module, type }) => {
    if (module === integrationModuleTypes.EXPORT) {
      return type !== integrationTypes.DATABASE;
    }

    return true;
  },
  needConnectionSettings: () => ({ type, module }) => {
    if (connectionlessExportIntegrations.includes(type)) {
      return module === integrationModuleTypes.IMPORT;
    }

    return !connectionlessIntegrations.includes(type);
  }
};

const mutations = {
  [types.SET_INTEGRATIONS](state, { module, value }) {
    Vue.set(state.integrations, module, value);
  },
  [types.SET_AVAILABLE_INTEGRATIONS](state, { module, value }) {
    Vue.set(state.available_integrations, module, value);
  },
  [types.SET_PROJECT_SETTINGS](state, value) {
    Object.assign(state.project_settings, value);
  },
  [types.SET_ACTIVE_INTEGRATION](state, value) {
    state.active_integration = value;
  },
  [types.SET_ACTIVE_STEPS](state, value) {
    state.active_steps = value;
  },
  [types.UPDATE_STEP](state, { index, value }) {
    Object.assign(state.active_steps[index], value);
  },
  [types.SET_INTEGRATION_INITIALIZED](state, value) {
    state.integration_initialized = value;
  },
  [types.SET_AGGREGATION_STATUS](state, value) {
    state.aggregation_status = value;
  },
  [types.SET_CREATION_STATUS](state, value) {
    state.creation_status = value;
  },
  [types.SET_IS_EDITED](state, value) {
    state.is_edited = value;
  },
  [types.RESET_STATE](state, ignoreKeys = []) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      if (initial[key] !== undefined && !ignoreKeys.includes(key)) {
        state[key] = initial[key];
      }
    });
  }
};

const actions = {
  async fetchIntegrations(_, params) {
    try {
      const response = await integrationsApi.getIntegrations(params);

      return response?.data || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExportIntegrations' });
    }
  },
  async fetchImportIntegrations({ commit, dispatch }) {
    try {
      const params = {
        module: integrationModuleTypes.IMPORT
      };
      const integrations = await dispatch('fetchIntegrations', params);

      commit(types.SET_INTEGRATIONS, {
        module: integrationModuleTypes.IMPORT,
        value: IntegrationsResponseAdapter({ integrations, ...params })
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchImportIntegrations' });
    }
  },
  async fetchExportIntegrations({ commit, dispatch }) {
    try {
      const value = [];

      for (const exportType of Object.values(integrationExportTypes)) {
        const params = {
          module: integrationModuleTypes.EXPORT,
          exportType
        };
        const integrations = await dispatch('fetchIntegrations', params);

        value.push(...IntegrationsResponseAdapter({ integrations, ...params }));
      }

      commit(types.SET_INTEGRATIONS, {
        module: integrationModuleTypes.EXPORT,
        value
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExportIntegrations' });
    }
  },
  fetchAllIntegrations({ dispatch }) {
    return Promise.allSettled([
      dispatch('fetchImportIntegrations'),
      dispatch('fetchExportIntegrations')
    ]);
  },
  async fetchAvailableIntegrations({ commit }, params) {
    try {
      const { module } = params;
      const response = await integrationsApi.getAvailableIntegrations(params);
      const integrations = response?.data;

      if (!integrations) {
        return;
      }

      commit(types.SET_AVAILABLE_INTEGRATIONS, {
        module,
        value: AvailableIntegrationsResponseAdapter({ integrations, ...params })
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchAvailableIntegrations' });
    }
  },
  async fetchProjectSettings({ commit }) {
    try {
      const response = await integrationsApi.getProjectSettings();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_PROJECT_SETTINGS, ProjectSettingsResponseAdapter(data));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProjectSettings' });
    }
  },
  async updateProjectSettings({ commit }, { isValid = true, ...payload }) {
    try {
      commit(types.SET_PROJECT_SETTINGS, payload);

      if (!isValid) {
        return;
      }

      await integrationsApi.updateProjectSettings(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateProjectSettings' });
    }
  },
  async fetchProjectCreationInfo({ commit }) {
    try {
      const response = await integrationsApi.getProjectCreationInfo();

      if (!response?.data) {
        return;
      }

      commit(types.SET_AGGREGATION_STATUS, response.data?.aggregationStatus);
      commit(types.SET_CREATION_STATUS, response.data?.canCreateProject ?? false);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProjectCreationInfo' });
    }
  },
  async initializeIntegrations({ dispatch }) {
    try {
      await Promise.allSettled([
        dispatch('fetchAllIntegrations'),
        dispatch('fetchProjectSettings'),
        dispatch('fetchProjectCreationInfo')
      ]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'initializeIntegrations' });
    }
  },
  async renameIntegration(_, { name, ...params }) {
    try {
      await integrationsApi.renameIntegration(params, { name });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'renameIntegration' });
    }
  },
  async deleteIntegration(_, params) {
    try {
      await integrationsApi.deleteIntegration(params);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteIntegration' });
    }
  },
  async createIntegration(_, payload) {
    try {
      const { id, module, ...body } = payload;
      const response = await integrationsApi.createIntegration(
        {
          id,
          module
        },
        body);

      return {
        ...payload,
        id: response?.data?.payload?.id
      };
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createIntegration' });
    }
  },
  async fetchIntegrationById({ getters, commit, dispatch }, payload) {
    getters.needConnectionSettings(payload) && await dispatch('fetchConnectionSettings', payload);
    getters.needIntegrationSettings(payload) && await dispatch('fetchIntegrationSettings', payload);

    commit(types.SET_INTEGRATION_INITIALIZED, true);
  },
  async fetchIntegrationSettings({ state, rootState }, { id, module, type }) {
    try {
      const response = await integrationsApi.getIntegrations({
        id,
        module
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      const settings = ConfigurationResponseAdapter(
        data,
        state.active_integration,
        rootState.integrations.settings.connection
      );
      const uniqueActionByType = {
        // spreadsheet contains transform settings so it has its own store
        [integrationTypes.SPREADSHEET]: 'integrations/spreadsheet/setSettings'
      };

      if (uniqueActionByType[type]) {
        return this.dispatch(uniqueActionByType[type], settings);
      }

      this.dispatch('integrations/settings/setConfiguration', settings);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchIntegrationSettings' });
    }
  },
  async fetchConnectionSettings({ state }, { id, module }) {
    try {
      const response = await connectorApi.getConnectionSettings({
        id,
        module
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      this.dispatch('integrations/settings/setConnection', ConnectionResponseAdapter(data, state.active_integration));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchConnectionSettings' });
    }
  },
  async updateIntegrationSettings({ state, rootState }, data) {
    if (data) {
      await this.dispatch('integrations/settings/updateConfiguration', data);
    }

    const { id, module } = state.active_integration;

    return connectorApi.updateIntegrationSettings(
      {
        id,
        module
      },
      ConfigurationRequestAdapter(
        rootState.integrations.settings.configuration,
        state.active_integration
      )
    );
  },
  async updateConnectionSettings({ state, rootState }, data) {
    if (data) {
      await this.dispatch('integrations/settings/updateConnection', data);
    }

    const { id } = state.active_integration;

    return connectorApi.updateConnectionSettings(
      { id },
      ConnectionRequestAdapter(
        rootState.integrations.settings.connection,
        state.active_integration
      )
    );
  },
  connectToDatasource({ state }) {
    return connectorApi.connectToDatasource({
      id: state.active_integration?.id
    });
  },
  async testDatasourceConnection({ dispatch }) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => dispatch('connectToDatasource')
      });

      return operationData.successful;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'testDatasourceConnection' });

      throw e;
    }
  },
  setActiveIntegration({ commit }, payload) {
    commit(types.SET_ACTIVE_INTEGRATION, payload);
  },
  setActiveSteps({ commit }, payload) {
    commit(types.SET_ACTIVE_STEPS, payload);
  },
  switchActiveStep({ getters, commit }, index) {
    commit(types.UPDATE_STEP, {
      index: getters.activeStepIndex,
      value: {
        active: false
      }
    });

    commit(types.UPDATE_STEP, {
      index,
      value: {
        active: true
      }
    });
  },
  setActiveStepStatus({ getters, commit }, status) {
    commit(types.UPDATE_STEP, {
      index: getters.activeStepIndex,
      value: {
        status
      }
    });
  },
  updateStepByIndex({ commit }, payload) {
    commit(types.UPDATE_STEP, payload);
  },
  resetStatusToStep({ state, commit }, indexTo) {
    state.active_steps.forEach((step, index) => {
      if (index < indexTo) {
        return;
      }

      commit(types.UPDATE_STEP, {
        index,
        value: {
          status: stepStatuses.INCOMPLETE
        }
      });
    });
  },
  resetIntegrationState({ state, dispatch }) {
    const actionByType = {
      [integrationTypes.SPREADSHEET]: 'integrations/spreadsheet/resetState',
      [integrationTypes.BC365]: 'integrations/bc365/resetState'
    };

    if (actionByType[state.active_integration.type]) {
      this.dispatch(actionByType[state.active_integration.type]);
    }

    dispatch('resetActiveIntegration');
    this.dispatch('integrations/settings/resetState');
    this.dispatch('integrations/transform/resetState');
  },
  importIntegration({ state }) {
    return connectorApi.importIntegration({
      id: state.active_integration.id,
      module: state.active_integration.module
    });
  },
  importProject() {
    return integrationsApi.importProject();
  },
  reimportProject(_, { type, logLevel, storeResult = false }) {
    return integrationsApi.reimportProject({ type, logLevel, storeResult });
  },
  stepsParser({ dispatch }, data) {
    const { type, module, status } = data;
    const isNew = !status || status === integrationStatuses.UNINITIALIZED;
    const isSpreadsheet = type === integrationTypes.SPREADSHEET;
    const steps = integrationsConfig[type]?.steps?.[module] || {};

    // 1 - [import/export] UNINITIALIZED = first step
    if (isNew && !isSpreadsheet) {
      return getParsedSteps(steps);
    }

    const uniqueAction = {
      [integrationTypes.SPREADSHEET]: 'integrations/spreadsheet/stepsParser',
      [integrationTypes.DATABASE]: 'integrations/databaseRelatedStepsParser',
      [integrationTypes.SAP]: 'integrations/databaseRelatedStepsParser',
      [integrationTypes.NETSUITE]: 'integrations/databaseRelatedStepsParser',
      [integrationTypes.DYNAMICS_NAV]: 'integrations/databaseRelatedStepsParser'
    };

    if (uniqueAction[type]) {
      return this.dispatch(uniqueAction[type], {
        ...data,
        steps
      });
    }

    const parserActionByModule = {
      [integrationModuleTypes.IMPORT]: 'importStepsParser',
      [integrationModuleTypes.EXPORT]: 'exportStepsParser'
    };

    return dispatch(parserActionByModule[module], {
      ...data,
      steps,
      isNew
    });
  },
  importStepsParser(_, data) {
    const { status, steps } = data;

    // 2 - [import] LOADING || FAILED_TO_IMPORT = pull data step
    // 3 - [import] IMPORTED || POPULATED = transform step
    const pullDataStepStatuses = [
      integrationStatuses.LOADING,
      integrationStatuses.FAILED_TO_IMPORT
    ];
    const activeStep = pullDataStepStatuses.includes(status)
      ? integrationSteps.PULL_DATA
      : integrationSteps.TRANSFORM;
    const activeIndex = Object.keys(steps).findIndex(key => key === activeStep);

    const getStatus = (index) => {
      // include last step to completed if integration is populated
      const condition = status === integrationStatuses.POPULATED
        ? index <= activeIndex
        : index < activeIndex;

      return condition
        ? stepStatuses.COMPLETED
        : stepStatuses.INCOMPLETE;
    };

    return getParsedSteps(steps, activeIndex, getStatus);
  },
  exportStepsParser(_, { steps }) {
    // 4 - [export] NON UNINITIALIZED === POPULATED -- 12.01.2024
    const activeIndex = Object.keys(steps).length - 1;

    return getParsedSteps(steps, activeIndex, () => stepStatuses.COMPLETED);
  },
  // will be removed on server side steps implementation
  databaseRelatedStepsParser(_, data) {
    const { status, steps, exportType } = data;

    // [import] UNINITIALIZED = connect step
    // [import] LOADING || FAILED_TO_IMPORT || IMPORTED || POPULATED = pull data\set up step
    const activeStep = status === integrationStatuses.UNINITIALIZED
      ? integrationSteps.CONNECT
      : exportType
        ? integrationSteps.SETUP
        : integrationSteps.PULL_DATA;
    const activeIndex = Object.keys(steps).findIndex(key => key === activeStep);

    const getStatus = (index) => {
      // include last step to completed if integration is populated
      const condition = status === integrationStatuses.POPULATED
        ? index <= activeIndex
        : index < activeIndex;

      return condition
        ? stepStatuses.COMPLETED
        : stepStatuses.INCOMPLETE;
    };

    return getParsedSteps(steps, activeIndex, getStatus);
  },
  setIsEdited({ state, commit }, value) {
    preventTabClose(value);

    if (state.is_edited !== value) {
      commit(types.SET_IS_EDITED, value);
    }
  },
  resetActiveIntegration({ commit }) {
    commit(types.RESET_STATE, ['integrations', 'group_by']);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  modules: {
    settings,
    transform,
    spreadsheet,
    bc365,
    amazon,
    netsuite
  },
  state,
  mutations,
  actions,
  getters
};
